import { translateSingular } from '/i18n/translations'

export function formatSalaryScale(salaryScale, { language }) {
  if (salaryScale.salary_scale_min === 'SK A') return 'senior kader A'
  if (salaryScale.salary_scale_min === 'SK B') return 'senior kader B'

  const scale = [
    salaryScale.salary_scale_min,
    salaryScale.salary_scale_max
  ].filter(Boolean).join(' - ' )

  return translateSingular(language, 'job-salary-scale', { scale })
}
