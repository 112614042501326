import { formatSalaryScale } from './formatSalaryScale'
import { getSalaryRange } from '/machinery/getSalaryRange'
import { translateSingular } from '/i18n/translations'

export function getJobLabels(job, { language }) {
  const {
    city,
    countryCode,
    contractType,
    remoteType,
    salaryCurrency,
    salaryMax,
    salaryMin,
    hoursMax,
    salaryScale,
  } = job

  const formattedSalaryScale = salaryScale && formatSalaryScale(salaryScale, { language })

  const labels = [
    (remoteType) && [{ title: remoteType }],
    countryCode && { title: countryCode ? `${city} (${countryCode.toUpperCase()})` : city },
    (salaryMax && salaryMin) && [{
      title: [getSalaryRange({ locale: language, salaryMin, salaryMax, salaryCurrency }), salaryScale && `(${formattedSalaryScale})`].filter(Boolean).join(' '),
      type: 'salary'
    }],
    (hoursMax > 0) && [{ title: translateSingular(language, 'job-hours-max', { hours: hoursMax }) }],
    (contractType) && [{ title: contractType }],
  ].filter(Boolean).flatMap(identity)

  return labels
}

function identity(x) {
  return x
}
