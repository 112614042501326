import { getFirstOfArray } from './getFirstOfArray'
import { ensureFloat } from '/machinery/ensureValue'

export function mapJobData(job, { language }) {
  const {
    job_id: id,
    job_title: title,
    city,
    job_language: jobLanguage,
    country: countries,
    recruiter_name: recruiterName,
    recruiter_email: recruiterEmail,
    job_description: description,
    contract_type: contractTypes,
    remote_type: remoteTypes,
    job_branch: jobBranches,
    salary_currency: salaryCurrency,
    salary_min: salaryMin,
    salary_max: salaryMax,
    hours_max: hoursMax,
    date_start: dateStart,
    what_we_offer_type: whatWeOfferType,
    salary_scale: salaryScale,
    job_type: jobType
  } = job

  const [country] = countries || []
  const [contractType] = contractTypes || []
  const [remoteType] = remoteTypes || []

  return {
    id,
    title,
    city,
    country,
    countryName: country ? country[`label_${language}`] : null,
    countryCode: country ? country.code : null,
    contractType: contractType ? contractType[`label_${language}`] : null,
    remoteType: remoteType ? remoteType[`label_${language}`] : null,
    recruiterName,
    recruiterEmail,
    description,
    jobLanguage,
    jobBranches,
    salaryCurrency,
    salaryMax,
    salaryMin,
    whatWeOfferType,
    hoursMax: ensureFloat(hoursMax, 0),
    dateStart,
    salaryScale,
    jobType: getFirstOfArray(jobType)?.code
  }
}
