import qs from 'query-string'

import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useQueryString, defaultOptions as options } from '@kaliber/use-query-string'
import { Icon } from '/features/buildingBlocks/Icon'

import arrowIcon from '/images/icons/arrow.raw.svg'

import styles from './JobAlert.css'

export function JobAlert({ layoutClassName }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const [query] = useQueryString()

  const queryString = query ? `?${qs.stringify(query, options.stringify)}` : ''

  return (
    <div className={cx(styles.component, layoutClassName)} data-style-context="blue">
      <div className={styles.content}>
        <a
          href={`${routeMap.app.jobAlert({ language })}${queryString}`}
          data-x='goto-job-alerts'
          className={styles.link}
        >
          {__`job-alert-title`}
        </a>
        <p className={styles.text}>{__`job-alert-description`}</p>
      </div>
      <div className={styles.icon}><Icon icon={arrowIcon} /></div>
    </div>
  )
}
